import './Footer.css';
import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import {
  //FaFacebook,
  //FaInstagram,
  //FaYoutube,
  //FaTwitter,
  FaLinkedin,
  FaWhatsapp,
} from 'react-icons/fa';
import DkLogo  from '../../../DkQaGray.svg'
import { SiGmail } from "react-icons/si";

function Footer() {
  const [click, setClick] = useState(false);
  //const [button, setButton] = useState(true);

  //const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(click);


  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-text'>
        “Quality is not an act, it is a habit”
        </p>
        <h4 className='footer-subscription-heading'>
        info@dkqa.com.mx
        </h4>
      </section>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              <img className='logo__navbar-definitive' src={DkLogo} alt='' height="140px" width="160px"/>
                
            </Link>
            </Link>
          </div>
          <small className='website-rights'>Dk Solutions © 2021</small>
          <div className='social-icons'>
            {/*<Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
            <Link
              className='social-icon-link'
              to={
                '//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
              }
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link>*/}
            <Link
              className='social-icon-link'
              to={
                '//api.whatsapp.com/send?phone=5213320381085'
              }
              target="_blank"
              aria-label='WhatsApp'
            >
              <FaWhatsapp />
            </Link>
            <a
              className='social-icon-link'
              href="mailto:info@dkqa.com.mx"
              aria-label='gmail'
            >
              <SiGmail />
            </a>
            <Link
              className='social-icon-link'
              to={
                '//www.linkedin.com/company/dk-solutions-qa/?viewAsMember=true'
              }
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;