export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Software Testing',
    headline: 'Automation Engineering & Testing',
    description:
      'QA should start from the inception of projects such as reviewing specifications e.g. user stories, functional requirements and other documents. Test planning and schedule should be done as early as possible',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: 'images/QAportada.svg',
    alt: 'Credit Card'
  };

export const homeObjTwo = {
lightBg: false,
lightText: true,
lightTextDesc: true,
topLine: 'FIND BUGS EARLY',
headline: 'Prioritize bug fixes based on usage',
description:
    'With multiple defects with the same priority and severity checking usage data can allow bugs in code which gets used more frequently to be resolved faster.',
buttonLabel: 'Learn More',
imgStart: '',
img: 'images/bugImage.svg',
alt: 'Vault'
};
  
export const homeObjThree = {
lightBg: true,
lightText: false,
lightTextDesc: false,
topLine: 'IN CONCRETE TERMS',
headline: 'Test Early Test Often’ Principle’ - Test throughout the Software Development Life-cycle (SDLC)',
description:
    "All the way from project inception, QA should be performed throughout the project life-cycle at all phases (including requirements gathering).",
buttonLabel: 'Start Now',
imgStart: 'start',
img: 'images/TestEarlyImage.svg',
alt: 'Vault'
};

export const homeObjFour = {
lightBg: false,
lightText: true,
lightTextDesc: true,
topLine: 'DATA ANALYTICS',
headline: 'Every transaction is stored on our secure cloud database',
description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
buttonLabel: 'Sign Up Now',
imgStart: 'start',
img: 'images/svg-8.svg',
alt: 'Vault'
};