import React from 'react';
import { Button } from './Button';
import './Pricing.css';
import { IconContext } from 'react-icons/lib';
import { VscSearch } from "react-icons/vsc";
import { SiSelenium, SiCypress, SiJenkins } from "react-icons/si"

function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <div className='pricing__section'>
        <div className='pricing__wrapper'>
          <h1 className='pricing__heading'>Our Services</h1>
          <div className='pricing__container'>
          <a href="mailto:info@dkqa.com.mx" className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <SiJenkins />
                </div>
                <h3>CI/CD</h3>
                <h4>Tools</h4>
                <p>such as jenkins</p>
                <ul className='pricing__container-features'>
                  <li>and more...</li>
                </ul>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Learn More
                </Button>
              </div>
            </a>
            <a href="mailto:info@dkqa.com.mx" className='pricing__container-card'> 
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <SiSelenium />
                </div>
                <div className='icon'>
                  <SiCypress />
                </div>
                <p>And More...</p>
                <h3>Automation Testing</h3>
                <ul className='pricing__container-features'>
                </ul>
                <Button buttonSize='btn--wide' buttonColor='blue'>
                  Contact Us
                </Button>
              </div>
            </a>
            <a href="mailto:info@dkqa.com.mx" className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <VscSearch />
                </div>
                <h3>Functional Testing</h3>
                <ul className='pricing__container-features'>
                  <li>API Testing</li>
                  <li>Unit Test</li>
                  <li>UAT</li>
                  <p>And Much More...</p>
                </ul>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Learn More
                </Button>
              </div>
            </a>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}
export default Pricing;