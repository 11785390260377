import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Footer from "./components/pages/Footer/Footer";
import Home from "./components/pages/Homepage/Home";
//import Services from "./components/pages/Services/Services";
//import About from "./components/pages/AboutUs/About";
import Contact from "./components/pages/Contact/Contact";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}/>
        {/*<Route path="/services" element={<Services />}/>
        <Route path="/about" element={<About />}/>*/}
        <Route path="/contact" element={<Contact />}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
