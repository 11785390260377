import React from 'react'
import HeroSection from '../../HeroSection'
import { homeObjOne} from './Data'

function Contact() {
    return (
        <>
            <HeroSection {...homeObjOne }/>
        </>
    )
}

export default Contact